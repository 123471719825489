var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_vm._m(0),_c('div',{staticClass:"panorama"},[_vm._m(1),_c('div',{staticClass:"productTab"},[_c('div',{staticClass:"tabs"},[_c('span',{class:_vm.isActive? 'active' : '',on:{"click":function($event){_vm.isActive = true}}},[_vm._v("按产品形态选择产品")]),_c('span',{class:_vm.isActive? '' : 'active',staticStyle:{"margin-left":"10%"},on:{"click":function($event){_vm.isActive = false}}},[_vm._v("按应用场景选择产品")])]),(_vm.isActive)?_c('div',{staticClass:"productChildren"},_vm._l((_vm.productChildren),function(item,index){return _c('div',{key:index,class:item.class},[_c('div',{staticClass:"text"},[_c('p',{staticClass:"title"},[_c('span',{staticStyle:{"width":"0.08rem","height":"0.08rem","background":"#0061E3","border-radius":"50%","display":"inline-block","position":"relative","top":"-3px","left":"-10px"}}),_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.spanList),function(i,ind){return _c('div',{key:ind},[_c('span',{class:i.active ? 'active' : '',on:{"mouseleave":function($event){item.spanList.forEach(j => {
                                    j.active = false;
                                  });i.active = true},"mousemove":function($event){return item.spanList.forEach(j => {
                                     j.active = false;
                                     i.active = true;
                                  })},"click":function($event){return _vm.toRoute('/productDetail',i)}}},[_vm._v(_vm._s(i.name))]),(i.active)?_c('img',{staticStyle:{"width":"2rem","max-height":"2rem","position":"absolute","right":"0","top":"20%"},attrs:{"src":i.url,"alt":""}}):_vm._e()])})],2)])}),0):_vm._e(),(!_vm.isActive)?_c('div',{staticClass:"productChildren application"},_vm._l((_vm.applicationChildren),function(item,index){return _c('div',{key:index,class:item.class},[_c('div',{staticClass:"text"},[_c('p',{staticClass:"title"},[_c('span',{staticStyle:{"width":"0.08rem","height":"0.08rem","background":"#0061E3","border-radius":"50%","display":"inline-block","position":"relative","top":"-3px","left":"-10px"}}),_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.spanList),function(i,ind){return _c('div',{key:ind},[_c('span',{class:i.active ? 'active' : '',on:{"mouseleave":function($event){item.spanList.forEach(j => {
                                    j.active = false;
                                  });i.active = true},"mousemove":function($event){return item.spanList.forEach(j => {
                                     j.active = false;
                                     i.active = true;
                                  })},"click":function($event){return _vm.toRoute('/productDetail',i)}}},[_vm._v(_vm._s(i.name))]),(i.active)?_c('img',{staticStyle:{"width":"1.8rem","position":"absolute","right":"0","top":"30%"},attrs:{"src":i.url,"alt":""}}):_vm._e()])})],2)])}),0):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('img',{staticClass:"bannerImg",attrs:{"src":require("../assets/images/productBannar.png"),"alt":""}}),_c('div',{staticClass:"banner"},[_c('p',{staticClass:"title"},[_vm._v("智算中心是新型生产力")]),_c('p',[_vm._v("正在赋能千行百业")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advantageCard"},[_c('div',{staticClass:"left"},[_c('span',{staticClass:"borderLine"}),_c('p',{staticClass:"backgroundText"},[_vm._v("Product Overview Specs")]),_c('p',{staticClass:"productTitle"},[_vm._v("产品概述")])]),_c('div',{staticClass:"right"},[_vm._v(" 中科逆熵科技有限公司负责南京智能计算中心的运营、推广和维护服务，开展AI中台系统研发。 依托平台提供行业应用、创新创业、教育科研、论坛大赛等服务，构建智能芯片的生态合作圈， 倾力打造“核心平台+数据运营+生态合作“的智能产业生态圈， 促进人工智能技术与传统产业结合，面向全球提供人工智能算力服务 中科逆熵科技有限公司负责南京智能计算中心的运营、推广和维护服务，开展AI中台系统研发。 依托平台提供行业应用、创新创业、教育科研、论坛大赛等服务，构建智能芯片的生态合作圈， 倾力打造“核心平台+数据运营+生态合作“的智能产业生态圈， 促进人工智能技术与传统产业结合，面向全球提供人工智能算力服务 ")])])
}]

export { render, staticRenderFns }