<template>
    <div class="product">
        <div style="position:relative">
          <img class="bannerImg" src="../assets/images/productBannar.png" alt="">
        <div class="banner">
            <p class="title">智算中心是新型生产力</p>
            <p>正在赋能千行百业</p>
        </div>
        </div>
        <div class="panorama">
            <div class="advantageCard">
                <div class="left">
                    <span class="borderLine"></span>
                    <p class="backgroundText">Product Overview Specs</p>
                    <p class="productTitle">产品概述</p>
                </div>
                <div class="right">
                    中科逆熵科技有限公司负责南京智能计算中心的运营、推广和维护服务，开展AI中台系统研发。
                    依托平台提供行业应用、创新创业、教育科研、论坛大赛等服务，构建智能芯片的生态合作圈，
                    倾力打造“核心平台+数据运营+生态合作“的智能产业生态圈，
                    促进人工智能技术与传统产业结合，面向全球提供人工智能算力服务
                    中科逆熵科技有限公司负责南京智能计算中心的运营、推广和维护服务，开展AI中台系统研发。
                    依托平台提供行业应用、创新创业、教育科研、论坛大赛等服务，构建智能芯片的生态合作圈，
                    倾力打造“核心平台+数据运营+生态合作“的智能产业生态圈，
                    促进人工智能技术与传统产业结合，面向全球提供人工智能算力服务
                </div>
            </div>
            <div class="productTab">
                <div class="tabs">
                    <span :class="isActive? 'active' : ''" @click="isActive = true">按产品形态选择产品</span>
                    <span :class="isActive? '' : 'active'" @click="isActive = false" style="margin-left: 10%">按应用场景选择产品</span>
                </div>
                <div class="productChildren" v-if="isActive">
                    <div :class="item.class" v-for="(item, index) in productChildren" :key="index">
                        <div class="text">
                            <p class="title">
                                <span style="width: 0.08rem;height: 0.08rem;background: #0061E3;border-radius: 50%;display: inline-block;position: relative;top: -3px;left: -10px"></span>
                                {{ item.title }}
                            </p>
                            <div v-for="(i , ind) in item.spanList" :key="ind">
                                <span  :class="i.active ? 'active' : ''"
                                       @mouseleave="item.spanList.forEach(j => {
                                        j.active = false;
                                      });i.active = true"
                                       @mousemove="item.spanList.forEach(j => {
                                         j.active = false;
                                         i.active = true;
                                      })"
                                       @click="toRoute('/productDetail',i)">{{i.name}}</span>
                                <img v-if="i.active" style="width: 2rem;max-height:2rem;position: absolute;right: 0;top: 20%;" :src="i.url" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="productChildren application" v-if="!isActive">
                    <div :class="item.class" v-for="(item, index) in applicationChildren" :key="index">
                        <div class="text">
                            <p class="title">
                                <span style="width: 0.08rem;height: 0.08rem;background: #0061E3;border-radius: 50%;display: inline-block;position: relative;top: -3px;left: -10px"></span>
                                {{ item.title }}
                            </p>
                            <div v-for="(i , ind) in item.spanList" :key="ind">
                                <span  :class="i.active ? 'active' : ''"
                                       @mouseleave="item.spanList.forEach(j => {
                                        j.active = false;
                                      });i.active = true"
                                       @mousemove="item.spanList.forEach(j => {
                                         j.active = false;
                                         i.active = true;
                                      })"
                                       @click="toRoute('/productDetail',i)">{{i.name}}</span>
                                <img v-if="i.active" style="width: 1.8rem;position: absolute;right: 0;top: 30%;" :src="i.url" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="aiTitle">
                <div class="left">
                    <span class="borderLine"></span>
                    <p class="backgroundText">AI Dynamics</p>
                    <p class="productTitle">AI动态</p>
                </div>
                <el-button type="primary" class='moreNewsBtn' @click="toRoute('/news')" icon="el-icon-right">更多资讯</el-button>
            </div>
            <div class="aiNews">
                <div class="newsChildren">
                    <img style="width: 100%" src="../assets/images/ai1.png" alt="">
                    <div class="newsContent">
                        <p class="newsContent-time">2021-11-09</p>
                        <span @click="$router.push('/news')" class="newsContent-title">绝知此事要躬行—南京智能计算中心运营实践</span>
                        <el-button type="text" icon="el-icon-right" class="newsButton" @click="$router.push('/news')"></el-button>
                    </div>
                </div>
                <div class="newsChildren" style="margin: 0 5%">
                    <img style="width: 100%" src="../assets/images/ai2.png" alt="">
                    <div class="newsContent">
                        <p class="newsContent-time">2021-11-09</p>
                        <span @click="$router.push('/news')" class="newsContent-title">南京智算中心点“睛”之笔，让LED灯珠检测...</span>
                        <el-button type="text" icon="el-icon-right" class="newsButton" @click="$router.push('/news')"></el-button>
                    </div>
                </div>
                <div class="newsChildren">
                    <img style="width: 100%" src="../assets/images/ai3.png" alt="">
                    <div class="newsContent">
                        <p class="newsContent-time">2021-11-09</p>
                        <span @click="$router.push('/news')" class="newsContent-title">南京智算中心支持西交利物浦AI实训课程顺利开展</span>
                        <el-button type="text" icon="el-icon-right" class="newsButton" @click="$router.push('/news')"></el-button>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</template>
<script>
/* eslint-disable */
import newsData from '@/assets/js/news.js'
export default {
    name: 'Home',
    data () {
        return {
            isActive: true,
            productChildren: newsData.productList,
            applicationChildren: newsData.applicationList,
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
        toRoute (val,item) {
            this.$router.push({
                path:val,
                query:{
                    info:JSON.stringify(item)
                }
            })
        }
    }

}
</script>
<style lang="scss" >
.product{
    .banner{
       left:10%;
        transform: translateY(-60%);
        p:nth-child(1){
         display: inline-block;
         line-height: 0.5rem;
         font-size: 0.4rem;
        }
       p:nth-child(2){
        font-size: 0.4rem;
        margin-top: 0.2rem;
        display: block;
        border-bottom: none;
       }
    }
}
.moreNewsBtn{
    position: relative;
    top:1rem;
    float: right;
}
.product {
    width: 100%;
    height: 100%;
    .panorama {
        text-align: center;
        width: 80%;
        margin: 0 auto;
        .aiNews {
            width: 80%;
            height: 3rem;
            margin: 0.5rem auto 0 auto;
            .newsChildren {
                display: inline-block;
                width: 30%;
                .newsContent{
                    width: 100%;
                    position:relative;
                    justify-content: space-around;
                    text-align: left;
                    flex-direction: column;
                    &-time{
                        font-size: 0.12rem;
                        color:#909399;
                        margin: 0.1rem 0;
                    }
                    &-title{
                        font-size: 0.14rem;
                        color: #606266;
                    }
                    &-title:hover {
                        color: #196ECE;
                    }
                    .newsButton{
                        position: absolute;
                        top:50%;
                        right: 0;
                        width: 28px;
                        height: 28px;
                        background: #196ECE;
                        border-radius: 50%;
                        .el-icon-right{
                            font-size: 0.20rem;
                            font-weight: 800;
                            position: relative;
                            bottom: 4px;
                            left: 0;
                        }
                    }
                }
            }
        }
        .advantageCard {
            width: 80%;
            height: 2rem;
            margin: 0.5rem auto 0 auto;
            .left {
                width: 20%;
                height: 100%;
                float: left;
                position: relative;
                text-align: left;
                .borderLine {
                    width: 0.8rem;
                    background-color: #0061E3;
                    display: inline-block;
                    height: 1px;
                }
                .productTitle {
                    font-size: 0.32rem;
                    padding: 0.2rem 0;
                }
                .backgroundText {
                    opacity: 0.8;
                    font-size: 16px;
                    color: #0061E3;
                    padding-top: 0.2rem;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .right {
                width: 70%;
                text-align: left;
                float: left;
                padding-left: 0.4rem;
                color: #999999;
                font-size: 0.14rem;
                line-height: 0.28rem;
            }
        }
        .productTab {
            width: 80%;
            height: 8rem;
            margin: 0 auto;
            .productChildren {
                //margin-bottom: 0.4rem;
                height: 3rem;
                .left, .right {
                    display: inline-block;
                    width: 40%;
                    height: 100%;
                    background-color: #F7F7F7;
                    border-top: 2px solid #0061E3;
                    text-align: left;
                    position: relative;
                    margin-bottom: 0.4rem;
                    box-shadow: 0px 3px 8px 0px rgba(187, 187, 187, 0.3);
                    .text {
                        display: inline-block;
                        width: 70%;
                        height: 100%;
                        overflow: hidden;
                        padding: 0.4rem 0.7rem 0.6rem 0.6rem;
                        .title {
                            color: #0061E3;
                            font-size: 0.20rem;
                        }
                        div {
                            padding: 0.2rem;
                            font-size: 0.2rem;
                            span {
                                padding: 0.1rem;
                                cursor: pointer;
                            }
                            .active {
                                border-bottom: 1px solid #0061E3;
                                color: #0061E3;
                            }
                        }
                    }
                }
                .right {
                    margin-left: 10%;
                }
            }
            .application {
                height: 4rem;
                overflow: hidden;
            }
            .tabs {
                margin-bottom: 0.4rem;
                span {
                    display: inline-block;
                    width: 40%;
                    height: 0.64rem;
                    border-radius: 0.03rem;
                    background: #F7F7F7;
                    cursor: pointer;
                    color: #0061E3;
                    line-height: 0.6rem;
                    font-size: 0.24rem;
                }
                .active {
                    background: #0061E3;
                    color: #FFFFFF;
                }
            }
        }
        .aiTitle {
            width: 80%;
            height: 1rem;
            margin: 0.5rem auto 0 auto;
            .left {
                width: 20%;
                height: 100%;
                float: left;
                position: relative;
                text-align: left;
                .borderLine {
                    width: 0.8rem;
                    background-color: #0061E3;
                    display: inline-block;
                    height: 1px;
                }
                .productTitle {
                    font-size: 0.32rem;
                    padding: 0.2rem 0;
                }
                .backgroundText {
                    opacity: 0.8;
                    font-size: 16px;
                    color: #0061E3;
                    padding-top: 0.2rem;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

</style>
